/*!
* Version: 1.1.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import './bootstrap/functions';
// Variables
@import './hope-ui-design-system/variable';
@import './hope-ui-design-system/variables/index';
@import './bootstrap/variables';

@import './bootstrap/mixins';

// Hope Ui Design System Mixin And Helper
@import './hope-ui-design-system/helper/functions';
@import './hope-ui-design-system/helper/mixins';
@import './hope-ui-design-system/helper/reboot';

@import './custom/auth/authentication';
@import './custom/kanban/kanban';
@import './custom/pricing/pricing';
@import './custom/ui-kit/ui-kit';

.half-title:after {
  border: solid 1px transparent;
  border-color: inherit;
  content: '';
  display: block;
  height: 0;
  border-style: dashed;
}

.multiselect {
  border: 1px solid #a3a3a3 !important;
  border-radius: 0.3em !important;
}

.multiselect__placeholder {
  color: #adadad;
  padding-left: 5px;
  margin-bottom: 8px;
}

.multiselect__option--highlight {
  background: #4a5073 !important;
}

.multiselect__option--highlight:after {
  background: #4a5073 !important;
}

.multiselect__tag {
  background: #4a5073 !important;
}

.multiselect__tag-icon:after {
  color: $danger !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: transparent !important;
}

.multiselect__tags {
  padding: 5px 40px 0 8px !important;
}

.is-invalid {
  border-color: $danger !important;
}

.is-invalid:focus {
  border-color: $danger !important;
  box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}

.bg-image {
  background: url('~@assets/images/background/portada.jpg') center/cover
    no-repeat;
  height: 100vh;
}

.btn-custom {
  color: #fff;
  background-color: $coopetro;
  border-color: $coopetro;
}

.btn-custom:hover {
  color: #fff;
  background-color: #c76b29;
  border-color: $coopetro;
}

.btn-custom2 {
  color: #fff;
  background-color: $coopetro2;
  border-color: $coopetro2;
}

.btn-custom2:hover {
  color: #fff;
  background-color: #012752;
  border-color: $coopetro2;
}

.bg-gray-custom {
  background: #f5f5f5;
}

.bg-custom {
  color: #fff;
  background-color: $coopetro !important;
  border-color: $coopetro;
}

.bg-custom2 {
  color: #fff !important;
  font-weight: 500;
  background-color: $coopetro2 !important;
  border-color: $coopetro2 !important;
}

.bg-custom3 {
  background-color: $coopetro3 !important;
  border-color: $coopetro3 !important;
}

.bg-custom4 {
  color: #fff !important;
  background-color: $coopetro4 !important;
  border-color: $coopetro4 !important;
}

.brand-navbar {
  width: 10em;
}

.mr-2 {
  margin-right: 0.5em;
}

.overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
  cursor: progress;
}

.overlay-show {
  display: flex !important;
}

.border-primary-opacity {
  border-color: rgb(38 43 64 / 20%) !important;
}

.spinner {
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  position: absolute;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgb(31 41 55 / 15%);
  border-right: 6px solid rgb(31 41 55 / 15%);
  border-bottom: 6px solid rgb(31 41 55 / 15%);
  border-top: 6px solid rgb(31 41 55);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.btn-upload {
  position: relative;

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    text-align: 0;
    cursor: pointer;
    opacity: 0;
  }
}

.icon-import {
  font-size: 5em;
  margin-top: 0.2em;
}

.text-custom2 {
  --bs-text-opacity: 1;
  color: $coopetro2 !important;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
