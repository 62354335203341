.sidebar {
  background-color: $card-bg;
  .navbar-brand {
    .logo-title {
      color: $white;
    }
  }
  .sidebar-header {
    border-color: $border-color;
  }
  @include nav-item(false) {
    color: $light;
  }
  @include nav-item() {
    color: $body-color;
    &.active,
    &[aria-expanded='true'] {
      @include sidebar-item-color-varients($white, $primary);
    }
    &:hover:not(.active):not([aria-expanded='true']) {
      @include sidebar-nav-item-hover($primary);
    }
  }
}
